import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import user from "./user";
import address from "./address";
import deposit from "./deposit";
import name from "./name";
import pkg from "./package";
import network from "./network";
import loading from "./loading";
import payments from "./payments";

const reducer = combineReducers({
  user,
  address,
  deposit,
  pkg,
  loading,
  network,
  payments,
  name,
});

const store = configureStore({
  reducer,
});

export default store;
