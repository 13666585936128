import Nav from "./Nav";

const Terms = () => {
  return (
    <div className="sub-container">
      <div className="content">
        <div className="content-terms">
          <div className="title">Personal Data Protection Policy</div>

          <p>
            Personal Data protection is important to [ FLUXDRIVE ](Hereafter
            “the company”)
          </p>
          <ul>
            <li>
              <dd>
                This policy explains how the company manages the personal
                information it holds. This policy applies to the use of the
                company's “CRETA Pre-Market” (hereinafter “Service”). Unless
                otherwise defined in this Policy, terms used in this Policy have
                the same meanings as the Company's End User License Agreement.
              </dd>
            </li>
            <li>
              <dd>
                This Policy supplements, but does not replace, the consent you
                have previously provided in relation to your personal
                information, and your consent gives the Company the right to
                lawfully collect, process, provide and disclose your personal
                information. This Policy does not affect the statutory rights we
                have in relation to the collection, processing, provision and
                disclosure of your personal information.
              </dd>
            </li>
            <li>
              <dd>
                The company respects the confidentiality of personal information
                and personal privacy, and strives to comply with relevant
                privacy laws. However, to the extent permitted by applicable
                privacy laws, nothing in this Policy shall be construed as
                giving the Company any liability jointly with any third party.
              </dd>
            </li>
            <li>
              <dt>Personal Information</dt>
              <dd>
                <p>
                  "Personal Information" refers to any data or information that
                  can identify you a) by that information alone, or b) by
                  combining that information with other information. Your
                  personal information collected and processed by the company is
                  as follows:{" "}
                </p>
                <p>
                  Email address and other information related to you provided by
                  you via submission form or other means of interaction
                </p>
                <p>
                  Information about using "CRETA Service", your account (ID and
                  password), cookies, IP addresses, other payment information
                </p>
              </dd>
            </li>
            <li>
              <dt>Personal Information Collection</dt>
              <dd>
                <p>
                  The company generally collect personal data through these
                  methods:{" "}
                </p>
                <p>
                  When you subscribe “Services” and receive news through email.
                </p>
                <p>
                  Collection through automatic collection system such as IP
                  address, your browser type, your operation system, your access
                  times. This information may also include your device name and
                  type, your operating system, your phone number, your country
                  and other users via server log files, as well as any other
                  information you choose to provide; Collecting data through
                  automatic collection system
                </p>

                <p>Usage, Processing and Disclosure of Personal Information</p>

                <p>
                  Your personal information will be used, processed and
                  disclosed for the following purposes:
                </p>
                <p>- To provide the “asset” or “service” you requested;</p>
                <p>
                  - To communicate and respond to your questions, requests and
                  complains;
                </p>
                <p>
                  - To provide information about products or services you may be
                  interested in;
                </p>
                <p>
                  - To address, investigate, and facilitate progression of
                  issues;
                </p>
                <p>
                  - To comply with infrastructure management, business
                  operation, internal policies and procedures;
                </p>
                <p>
                  - To comply with all applicable rules, laws and regulations;
                </p>
                <p>
                  - To implement or apply the terms of use and other agreements
                  including claiming and collecting purposes;
                </p>
                <p>
                  - For legal rights, assets and safety or When disclosure is
                  necessary or deemed appropriate to protect end-users and
                  others reasons include and are not limited to:
                </p>
                <p>- Preventing risk and fraud;</p>
                <p>
                  - Answering questions or providing other types of support;
                </p>
                <p>- Providing and improving our services;</p>
                <p>- Providing reporting and analytics;</p>
                <p>- Testing out features or additional services;</p>
              </dd>
            </li>
            <li>
              <dt>Cross-border Transfer of Personal Information</dt>
              <dd>
                Your personal information may be provided, processed and stored
                on an external server located overseas other than your country
                of residence (hereinafter referred to as “overseas company”). In
                addition, as described below, in the course of proceeding the
                company's “services”, it may be necessary to provide your
                personal information to third parties located abroad. Certain
                countries may not have similar privacy standards as your country
                of residence. However, the company will take reasonable steps to
                ensure that your personal information transferred abroad is
                properly protected. In addition, the company verifies that the
                overseas transfer of personal information complies with the
                requirements of applicable Personal Information Protection Acts.
              </dd>
            </li>
            <li>
              <dt>
                Provide, Entrust processing, and Sharing Personal Information to
                a third-party
              </dt>
              <dd>
                <p>
                  The company may provide non-personally identifiable
                  statistical data and information to third parties without
                  limitation in the following case. In addition, we may provide
                  personal information collected by the company or provided by
                  you in accordance with due process.
                </p>
                <p>Subsidiaries and affiliates</p>
                <p>
                  To contractors, service providers, and other third parties we
                  use to support our business.
                </p>
                <p>
                  To a buyer or other successor in the event of a merger,
                  reorganization, dissolution, or other transfer or sale of some
                  or all of TSBMV Global Ltd.’s assets.
                </p>
                <p>To fulfill the purposes for which you provide it.</p>
                <p>
                  For any other purpose disclosed by us when you provide the
                  information.
                </p>
                <p>with your consent.</p>
                <p>
                  We share data with the following third-parties. These are
                  either controllers, or processors acting on our behalf. We
                  have linked to their respective policies and given a basic
                  description of what they do.
                </p>
                <p>
                  Google Analytics
                  <br />
                  Smartlook
                  <br />
                  Google Tags
                  <br />
                  Bitski
                  <br />
                </p>
              </dd>
            </li>
            <li>
              <dd>
                The company's “services” may include links to other websites,
                services, etc. operated by third parties, such as business
                partners, users who provide “user game services,” or “user game
                services”. The company is not responsible for the protection of
                personal information of the services provided by third parties
                (hereafter referred to as “third party services”) as above,
                unless it is obligatory under the applicable personal
                information protection laws. Therefore, if you wish to use
                “Third Party Services”, we recommend that you check the privacy
                policies of those third parties.
              </dd>
            </li>
            <li>
              <dd>
                Meanwhile, the company may entrust a part of the work related to
                the “service” to a third party, and in this case, the trustee
                may process personal information. The trustee will process your
                personal information only within the scope of the entrusted
                business, and the company will take measures to ensure that the
                trustee properly protects your personal information.
              </dd>
            </li>
            <li>
              <dt>Retention and Disposal of Personal Information</dt>
              <dd>
                In principle, your personal information will be processed and
                stored until the contract between you and the company is
                terminated. However, if it is necessary for the company to keep
                it under the relevant laws or if it is necessary for the
                legitimate exercise of the company's rights, it may be processed
                and stored for a period of time. If the above retention period
                has elapsed, the company will take measures so that your
                personal information can be deleted or stopped using technically
                possible and appropriate methods.
              </dd>
            </li>
            <li>
              <dt>Management, Security and Safety Measures</dt>
              <dd>
                The company has appointed a Data Protection Officer to oversee
                your privacy management in accordance with this policy and the
                relevant Data Protection Act. The company train staffs to
                respect the confidentiality of your personal information, and
                deal very seriously with violations of all Data Protection Acts.
                The company appropriately performs technical, managerial and
                physical measures necessary to ensure safety so that loss,
                theft, leakage, forgery, alteration, or damage is not caused.
              </dd>
            </li>
            <li>
              <dt>Cookie Policy</dt>
              <dd>
                The company uses "Cookie" to gather information about your
                activities and you from our website. Cookies are small pieces of
                data that we store on your computer and are accessed every time
                you visit us, so we can understand how you use our company and
                provide you with appropriate content based on your preferences.
                If you do not wish to allow the company collecting cookies, you
                can set your browser to restrict cookies, However, do understand
                that if the company doesn’t have cookies, the service you desire
                may not be available. This policy only covers the use of cookies
                between your computer and the company, and does not cover the
                use of cookies by other third-parties.
              </dd>
            </li>
            <li>
              <dt>
                RightsㆍObligations of Data Subjects and how to Exercise them
              </dt>
              <dd>
                You may directly view, access, correct, edit your personal
                information in the manner provided by the company. If necessary,
                you may request the Company to correct, edit or delete your
                personal information. You may also exercise any rights granted
                to you under applicable privacy laws. However, if there is a
                justifiable reason such as protection of the interests or rights
                of the company or a third party, contract execution,
                maintenanceㆍpreservation of accurate information, compliance
                with related laws, or technical modifications or deletions,
                etc., the processing may be suspended or refused.
              </dd>
            </li>

            <li>
              <dt>
                Anti-Money Laundering (“AML”) and Counter Financing Terrorism
                (“CFT”) policy:
              </dt>
              <dd>
                The dynamic and evolving nature of the financial and payments
                sectors present challenges for AML and CFT controls and policy
                in ensuring that it remains relevant and up to date.
              </dd>
            </li>

            <li>
              <dd>
                The dynamic and evolving nature of the financial and payments
                sectors present challenges for AML and CFT controls and policy
                in ensuring that it remains relevant and up to date.
              </dd>
            </li>

            <li>
              <dd>
                The dynamic and evolving nature of the financial and payments
                sectors present challenges for AML and CFT controls and policy
                in ensuring that it remains relevant and up to date.
              </dd>
            </li>

            <li>
              <dd>
                The objective of this policy is to ensure that you and
                third-party agents understand and abide by the requirements of
                anti-money laundering and terrorist financing laws and
                regulations while conducting and working with the company’s
                businesses.
              </dd>
            </li>

            <li>
              <dt>Personal Information of Minors</dt>
              <dd>
                The company does not intentionally collect personal information
                of minors. All users of the “Service” must be adults (above age
                of eighteen (18) years)., and minors can use the “Service” only
                when their parents, guardians, and other legal representatives
                express their intention to consent to the company. When the
                company learns that you are a minor, the company will take the
                best measures to properly handle such personal information as
                possible, such as deleting or stopping the use of the personal
                information as soon as possible. If you believe that the Company
                is in possession of the personal information of a minor, please
                contact the Company using the email address indicated below.
              </dd>
            </li>

            <li>
              <dt>Review on the Policy</dt>
              <dd>
                This policy will be reviewed frequently. The company can update
                this policy in consideration of new laws and technologies,
                changes in operational and practice practices, and changing
                business environments. Any changes or modifications will be
                effective immediately upon posting the updated Privacy Policy,
                and you waive the right to receive specific notice of each such
                change or modification. You are encouraged to periodically
                review this Privacy Policy to stay informed of updates. You will
                be deemed to have been made aware of, will be subject to, and
                will be deemed to have accepted the changes in any revised
                Privacy Policy by your continued use of BSO Mobile App after the
                date such revised Privacy Policy is posted. If you are unsure
                whether the policy you are reading is up to date, please contact
                us.
              </dd>
            </li>

            <li>
              <dd>
                If there is a difference between the English version and other
                translations, the English version will be prioritized.
              </dd>
            </li>
          </ul>
        </div>
      </div>

      <Nav />
    </div>
  );
};

export default Terms;
