import { Button } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import packageList from "./const";

const Packages = ({ onPayment, close }) => {
  const { deposit } = useSelector((state) => state.deposit);

  function payment(item) {
    onPayment(item);
  }

  return (
    <div className="resale-profit-wrap">
      <p className="membership-package">VIP PACK Currently on Sale</p>

      <div className="list con">
        <ul className="row">
          {packageList.map((item) => {
            return (
              <li className="cell" key={item.price}>
                {item.price > 0 ? (
                  <div className="card">
                    <div className="img-box">
                      <img src={item.img} alt="" />
                    </div>
                    <div className="product-name">{item.desc}</div>
                    <div className="product-price">
                      $
                      {Number(
                        item.price *
                          (1 + Number(process.env.REACT_APP_PAYMENT_FEE) / 100)
                      ).toFixed(0)}
                    </div>
                    <div className="product-buttons">
                      <Button
                        className="btn-buy"
                        onClick={() => payment(item)}
                        disabled={item.price <= deposit}
                      >
                        Buy
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="card">
                    <div className="img-box">
                      <img src={item.img} alt="" />
                    </div>
                    <div className="product-name"></div>
                    <div className="product-price">{item.desc}</div>
                    <div className="product-buttons">
                      <span className="btn btn-buy transparent">&nbsp;</span>
                    </div>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Packages;
