import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import Iframe from "react-iframe";
import "./App.css";
import { TransitionGroup } from "react-transition-group";

import Nav from "./components/Nav";
import Main from "./components/Main";
import Header from "./components/Header";
import Register from "./components/Register";
import History from "./components/History";
import Loading from "./components/Loading";
import Terms from "./components/Terms";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  // const dispatch = useDispatch();
  // const history = useHistory();
  const location = useLocation();

  return (
    <div className="App">
      <Loading />
      <Header />

      <TransitionGroup className="main-container">
        <Switch location={location}>
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms" component={Terms} />
          <Route path="/history" component={History} />
          <Route path="/loading" component={Loading} />
          <Route path="/register" component={Register} />
          <Route path="/" component={Main} />
        </Switch>
      </TransitionGroup>

      <Nav></Nav>
    </div>
  );
}

export default App;

// useEffect(() => {
//   history.push("/dashboard");
// }, []);

// function checkExists(address) {
//   axios
//     .get("api/checkExists", {
//       params: {
//         address: address,
//       },
//     })
//     .then((res) => {
//       // if (res.data) {
//       history.push("/dashboard");
//       // } else {
//       //   history.push("/dashboard");
//       // }
//     })
//     .catch((err) => {
//       if (err.response) {
//         console.log(err.response);
//         console.log(err.response.data.message);
//       }
//     });
// }

// function Connection() {
//   return (
//     <div>
//       <div className="diverseTitle"></div>
//       <div className="white.sh"></div>
//       <div className="dark_sh connect-btn" onClick={connectWallet}>
//         {process.env.REACT_APP_MODE === "dev" ? (
//           <span className="Connect-to-TronLink dev">
//             Connect to TronLink {process.env.REACT_APP_MODE}
//           </span>
//         ) : (
//           <span className="Connect-to-TronLink">Connect to TronLink</span>
//         )}
//       </div>
//       <div className="help-center">
//         <a href="mailto:support@metabridgeinvestment.com">
//           Help center : support@metabridgeinvestment.com
//         </a>
//         <p>© 2021 MetaBridge. ver 1.0.11</p>
//       </div>
//       <div className="diverseFoot"></div>
//     </div>
//   );
// }
