import Nav from "./Nav";
import { css } from "@emotion/react";

const Terms = () => {
  return (
    <div className="sub-container">
      <div className="content">
        <div className="content-terms">
          <div className="title">End User License Agreement</div>

          <p>
            These Terms and Conditions constitute a legally binding contract
            (hereinafter referred to as the “End User License Agreement”)
            between the User and [ FLUX DRIVE ] (hereafter “Company”, the User
            and the Company are each 'Party', collectively the 'Parties') in
            relation to access and use of the “CRETA Pre-Market” (hereinafter
            “Service”). These terms and conditions apply to the access and use
            of the “CRETA Pre-Market”.{" "}
          </p>
          <ul>
            <li>
              <dt>1. USER’S ACKNOWLEDGMENT AND ACCEPTANCE OF TERMS</dt>
              <dd>
                The Service offered is subject to your compliance with all of
                the terms and conditions contained herein and all other
                operating rules, policies and procedures. In addition, some
                applications offered through or in connection with the Service
                may be subject to additional terms and conditions promulgated by
                Company from time to time. Any material modifications to the
                Agreement, or our Privacy Policy will also be brought to your
                attention by posting the modified Agreement, via notification by
                email to the email address associated with the Service (if any),
                through the Service itself, or through any other appropriate
                measures as determined by Company in its sole discretion. Such
                material modifications will be effective upon your acceptance
                thereof and will apply to matters and events arising under the
                Agreement following the date of your acceptance. Note that if
                you do not accept the modified Agreement, or Privacy Policy, you
                may terminate this Agreement and must stop using the Service.
                Your continued use of the Service after a modification has been
                made to the Agreement, or Privacy Policy constitutes your
                acceptance of such modification and an acknowledgement that you
                have read the Privacy Policy.
              </dd>
            </li>
            <li>
              <dt>2. UPDATES AND PATCHES</dt>
              <dd>
                Company may provide patches, updates, or upgrades to the Service
                that must be installed in order for you to continue to use the
                Service. Company may update the Service remotely without
                notifying you, and you hereby consent to Company applying
                patches, updates, and upgrades. Company may modify, suspend,
                discontinue, substitute, replace, or limit your access to any
                aspect of the Service at any time. Company does not have any
                maintenance or support obligations with respect to the Service.
              </dd>
            </li>
            <li>
              <dt>
                3. COMPANY’S OWNERSHIP OF THE SERVICE AND PROPRIETARY MATERIALS
              </dt>
              <dd>
                The Service contains copyrighted material, technology,
                trademarks, service marks, trade secrets and other proprietary
                information, which may include computer code, text, data, video,
                images, illustrations, animations, sounds, musical compositions
                and recordings, audiovisual effects, color schemes, business
                methods and methods of operation, concepts, ideas, know-how,
                moral rights, and any related documentation (collectively the
                “Proprietary Material”). All intellectual property rights to the
                Proprietary Material, including without limitation patent,
                copyright, trademark and trade secret rights, are owned and/or
                licensed by Company. You: (A) agree not to copy, download,
                reproduce, republish, upload, post, transmit, perform, display,
                distribute or sell, or in any other way exploit the Proprietary
                Material, or to participate with or to encourage others to
                engage in such acts, without the prior written consent of
                Company; and (B) may not reverse engineer, disassemble,
                decompile, or translate any computer Service programs that
                comprise Proprietary Material, or otherwise attempt to derive
                the source code of such programs, except that the foregoing
                restrictions on copying or reverse engineering does not apply to
                the extent prohibited by applicable law. No Proprietary
                Material, or any portion thereof, may be modified or used for
                any purpose other than as expressly authorized in these Terms.
                The Proprietary Material may include materials licensed by
                Company from third parties, and the licensors of those materials
                may enforce their rights in the event of any violation of this
                Agreement.
              </dd>
            </li>
            <li>
              <dt>4. NO SALE OR ASSIGNMENT</dt>
              <dd>
                Company does not recognize the transfer of the Service, with the
                exception of Digital Assets as defined in (and in accordance
                with) Section 5 below. Other than as set forth in Section 5, you
                may not give, purchase, sell, bargain, barter, market, trade,
                offer for sale, sell, license, assign or otherwise divest your
                rights, responsibilities or obligations under the Agreement,
                either in whole or in part, without the prior written consent of
                Company. Any attempt to do so shall be void and of no effect.
                Company allows you the limited ability to trade Digital Assets
                with other users who lawfully possess the Service under
                conditions provided with the Digital Assets.
              </dd>
            </li>
            <li>
              <dt>5. DIGITAL ASSETS</dt>
              <dd>
                If the Service allows you to purchase and/or earn through play a
                license to use Digital Assets, the following additional terms
                and conditions apply. (A) The Service may enable users to (i)
                gain access to (and certain limited rights to use) Digital
                Assets within the Service; and (ii) Subject to the terms of and
                compliance with this Agreement, Company hereby grants you the
                nonexclusive, non-transferable, non-sublicensable, limited and
                revocable right and license to use Digital Assets obtained by
                you within the Service. Except as otherwise prohibited by
                applicable law, Digital Assets obtained by you are licensed to
                you, and you hereby acknowledge that no title or ownership in or
                to Digital Asset is being transferred or assigned hereunder.
                This Agreement should not be construed as a sale of any rights
                in Digital Assets. Digital Assets do not have an equivalent
                value in real currency and do not act as a substitute for real
                currency. You are prohibited from converting Digital Assets into
                a unit of value outside of the Service, such as actual currency
                or actual goods. You acknowledge and agree that Company may
                revise or take action that impacts the perceived value of or
                purchase price for any Digital Assets at any time except as
                prohibited by applicable law. Digital Assets do not incur fees
                for non-use; provided, however, that the license granted
                hereunder to Digital Assets will terminate in accordance with
                the terms and conditions of this Agreement and the Service
                documentation, when Company ceases providing the Service, or
                this Agreement is otherwise terminated. Company, in its sole
                discretion, reserves the right to charge fees for the right to
                access or use Digital Assets and/or may distribute Digital
                Assets with or without charge. Purchase and use of Digital
                Assets through Service are subject to the Service’s governing
                documents, including but not limited to, the End User License
                Agreement. All purchases of Digital Assets are final and under
                no circumstances will such purchases be transferable, or
                exchangeable. Except as prohibited by applicable law, Company
                has the absolute right to manage, regulate, control, modify,
                suspend, and/or eliminate such Digital Assets as it sees fit in
                its sole discretion, and Company shall have no liability to you
                or anyone else for the exercise of such rights.
              </dd>
            </li>
            <li>
              <dt>6. Disclaimer of Warranty, Limitation of Damages</dt>
              <dd>
                Company makes no warranty or representation of any kind, either
                express or implied, including but not limited to warranty of
                title or non-infringement or implied warranty of
                merchantability, fitness for a particular purpose,
                non-infringement, or other violation of rights in relation to
                the availability, accuracy, validity, reliability, or content of
                these pages and/or the site. Company shall not be liable for any
                direct, indirect, incidental, or consequential damages, lost
                profits or for business interruption arising out of the use of
                or inability to use this website, even if Company has been
                advised of the possibility of such damages.
              </dd>
            </li>
            <li>
              <dt>7. INDEMNIFICATION</dt>
              <dd>
                This Section only applies to the extent permitted by law. If you
                are prohibited by law from entering into the indemnification
                obligation below, then you assume, to the extent permitted by
                law, all liability for all claims, demands, actions, losses,
                liabilities, and expenses (including attorneys’ fees, costs and
                expert witnesses’ fees) that are the stated subject matter of
                the indemnification obligation below. You agree to defend,
                indemnify and hold harmless the Company Parties , from and
                against all claims, damages, obligations, losses, liabilities,
                costs or debt, and expenses (including but not limited to
                attorney fees, costs, and expert witnesses’ fees) arising from:
                (A) your use of the Service; (B) any claim, that, if true, would
                constitute your violation of any of the terms of this Agreement
                or negligence; (C) your violation of any third-party right,
                including without limitation any copyright, trade secret,
                privacy or property right; or (D) any claim that your
                User-Generated Content caused damage to a third-party. You agree
                to reimburse Company on demand for any defense costs incurred by
                Company and any payments made or loss suffered by Company,
                whether in court judgement or a settlement, based on any matter
                covered by this Section.
              </dd>
            </li>
            <li>
              <dt>8. PERSONAL INFORMATION PROTECTION</dt>
              <dd>
                The company strives to protect the personal information of users
                in accordance with relevant laws and regulations. the protection
                and use of personal information within the "Service" is subject
                to the relevant laws and regulations and the company's privacy
                policy. however, the company's personal information processing
                policy does not apply to "third party services".
              </dd>
            </li>
            <li>
              <dt>9. COOKIES</dt>
              <dd>
                We employ the use of cookies and/or similar technologies: web
                beacons, browser analysis tools, server logs, pixels, and mobile
                identifiers. by accessing our system, you agree to use cookies
                and/or similar technologies in agreement with the privacy
                policy. Most interactive mobile applications use cookies to
                retrieve their users’ details for each visit. cookies and
                similar technologies are used by the company to enable the
                functionality of certain areas to make it easier for you and any
                other registered accounts of company. Some of our
                affiliate/advertising partners may also use cookies and/or
                similar technologies.
              </dd>
            </li>
            <li>
              <dt>10. TERMINATION</dt>
              <dd>
                This Agreement is effective until terminated by you or by
                Company. This Agreement automatically terminates when Company
                ceases to operate the Service servers (for games exclusively
                operated online), if Company determines or believes your use of
                the Service involves or may involve fraud or money laundering or
                any other illicit activity, or upon your failure to comply with
                terms and conditions of this Agreement, including, but not
                limited to, the License Restrictions above. If this Agreement
                terminates due to your violation of this Agreement, Company may
                prohibit you from re-registering or re-accessing the Service.
              </dd>
            </li>
            <li>
              <dt>11. REFUND POLICY</dt>
              <dd>
                Contents purchased from the Service is refundable within
                fourteen days of purchase, so long as the Contents has not been
                consumed, modified or transferred. Please note that in some
                cases, Company will be unable to give refunds for some third
                party Contents. These exceptions will be clearly marked as
                nonrefundable on the Service page prior to purchase.
              </dd>
            </li>
            <li>
              <dt>12. INJUNCTION</dt>
              <dd>
                You hereby agree that if the terms of this Agreement are not
                specifically enforced, Company will be irreparably damaged, and
                therefore you agree that Company shall be entitled, without
                bond, other security, or proof of damages, to appropriate
                equitable remedies with respect any of this Agreement, including
                temporary and permanent injunctive relief, in addition to any
                other available remedies.
              </dd>
            </li>
            <li>
              <dt>13. GOVERNING LAW AND DISPUTE RESOLUTION</dt>
              <dd>
                In respect of disputes arising between the company and the user,
                United Arab Emirates, Dubai shall be the governing law. For any
                dispute arising between the Company and the User, the parties
                agree to first attempt to negotiate informally for at least 30
                days before initiating arbitration. Such informal negotiations
                begin with either party giving the other written notice. The
                parties agree that if the parties are unable to resolve the
                dispute through informal negotiation, any dispute shall be
                resolved only by arbitration at [Dubai International Arbitration
                Centre (DIAC)] in accordance with the [arbitration rules of
                Dubai International Arbitration Centre]. the arbitrator shall be
                [1 arbitrators] and the language of the arbitration shall be
                [English]. The arbitration decision is final, binding on the
                parties, and enforceable. The parties agree that all
                arbitrations shall be conducted solely in their individual
                capacity, not as a class action or other representative action,
                and the parties expressly waive their right to bring a class
                action or file a class action. In any case, any dispute (or
                cause of litigation) arising out of or in connection with the
                use of the "service" shall be filed within one year after the
                cause arises.
              </dd>
            </li>
          </ul>
        </div>
      </div>

      <Nav />
    </div>
  );
};

export default Terms;
