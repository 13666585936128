import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import ModalPotal from "react-modal";
import Packages from "./Packages";
import Payment from "./Payment";
import TroonLink from "./TroonLink";
import { useDispatch } from "react-redux";
import { setDeposit } from "../store/deposit";
import { setName } from "../store/name";
import { setLoading } from "../store/loading";
import { useHistory } from "react-router-dom";
import packageList from "./const";

function Main() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { address } = useSelector((state) => state.address);
  const { name } = useSelector((state) => state.name);
  const { deposit } = useSelector((state) => state.deposit);

  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [isTroonLinkModalOpen, setTroonLinkModalOpen] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState(null);

  function refreshPage() {
    console.log(">>>>>>>>>>>>> addr: " + address);

    getUserInfo();
  }

  useEffect(() => {
    refreshPage();
  }, [address]);

  function getUserInfo() {
    if (!address) {
      setDeposit(0);
      setName("");
      return;
    }

    dispatch(setLoading(true));

    axios
      .post("api/userInfo", {
        address: address,
      })
      .then((res) => {
        dispatch(setDeposit(res.data.deposit));
        dispatch(setName(res.data.name));

        if (process.env.REACT_APP_REQUIRED_TRON_LOGIN !== "false") {
          if (!res.data.name) {
            alert(
              "This address is not registered. Please sign up for our membership first.(code: 1001);"
            );
          }
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.response) {
          console.log(err.response);
          console.log(err.response.data.message);
        }
      });
  }

  const onPaymnet = (item) => {
    console.log(
      ">>>> onPaymnet: " +
        address +
        ", REACT_APP_REQUIRED_TRON_LOGIN: " +
        process.env.REACT_APP_REQUIRED_TRON_LOGIN,
      item
    );

    setSelectedPackage(item);

    if (process.env.REACT_APP_REQUIRED_TRON_LOGIN === "false") {
      setPaymentModalOpen(true);
    } else {
      if (address) {
        if (name) {
          setPaymentModalOpen(true);
        } else {
          alert(
            "This address is not registered. Please sign up for our membership first.(code: 1002)"
          );
        }
      } else {
        setTroonLinkModalOpen(true);
      }
    }
  };

  function closePaymentModal() {
    setPaymentModalOpen(false);
    refreshPage();
  }

  function closeTroonLinkModal() {
    setTroonLinkModalOpen(false);
    refreshPage();
  }

  return (
    <div id="dashboard" className="sub-container">
      <div className="content">
        <div
          className="content-title main"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/images/image_upper01.jpg"
            })`,
          }}
        >
          <div>
            <strong>What is CRETA ?</strong>
          </div>
          <div>
            A fully decentralized entertainment platform that allows you to
            enjoy media contents and virtual worlds, such as metaverses, as a
            player and also as a creator who creates your own games and world.
          </div>
          <div>
            Be prepared for the coming future by preoccupying items to be used
            on CRETA, the world's largest metaverse platform.
          </div>
        </div>
        {deposit > 0 ? (
          <div className="content-package">
            <p>
              Your package : <br></br>
              {packageList.find((p) => p.price === deposit).desc || ""}
            </p>
          </div>
        ) : (
          ""
        )}
        <Packages onPayment={onPaymnet} />
        <div className="content-bottom">
          Users who purchased items cannot purchase other items of the same or
          lower level, and if they purchase items of the higher level, the item
          will be automatically upgraded and user will pay the difference amount
          of the items.
        </div>
        <div className="text-right">
          Customer support :{" "}
          <a href="mailto:support@creta.world">support@creta.world</a> ver 1.0.2
        </div>
        {
          <ModalPotal
            closeTimeoutMS={200}
            isOpen={isPaymentModalOpen}
            onRequestClose={closePaymentModal}
            ariaHideApp={false}
            className="paymentModal"
          >
            <Payment
              close={closePaymentModal}
              selectedPackage={selectedPackage}
            />
          </ModalPotal>
        }
        {
          <ModalPotal
            closeTimeoutMS={200}
            isOpen={isTroonLinkModalOpen}
            onRequestClose={closeTroonLinkModal}
            ariaHideApp={false}
            className="troonLinkModal"
          >
            <TroonLink close={closeTroonLinkModal} />
          </ModalPotal>
        }
      </div>
    </div>
  );
}

export default Main;
