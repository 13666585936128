import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "deposit",
  initialState: {
    deposit: 0,
  },
  reducers: {
    setDeposit: (state, action) => {
      state.deposit = action.payload;
    },
    getDeposit: (state, action) => {
      return state.deposit;
    },
  },
});

export default slice.reducer;
export const { setDeposit, getDeposit } = slice.actions;
