import React, { Component } from "react";
import Select, { components } from "react-select";
import countryList from "react-select-country-list";

// https://codesandbox.io/s/8n87k7v73l
export function CountryFlag(props) {
  return (
    <span
      className={"flag-icon flag-icon-" + props.code}
      style={{ fontSize: props.size || "30px" }}
    />
  );
}

export const CountryFlagSelectOption = (props) => {
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center", color: "#000" }}>
        <CountryFlag size={props.flagSize} code={props.value.toLowerCase()} />

        <span>&nbsp;{props.label}</span>
      </div>
    </components.Option>
  );
};

export const CountryFlagValueContainer = ({ children, ...props }) => {
  const code = (props.hasValue && props.getValue()[0].value) || false;

  return (
    <div style={{ display: "flex", flexGrow: 1 }}>
      {(code && <CountryFlag code={code.toLowerCase()} />) || null}
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    </div>
  );
};

const styles = {
  valueContainer: (base, state) => {
    const height = "30px";
    return { ...base, height, color: "#000" };
  },
};

export default class CountrySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: countryList().getData(),
      value: null,
    };
  }

  changeHandler = (value) => {
    this.setState({ value });
    this.props.onChange(value);
  };

  render() {
    return (
      <Select
        styles={styles}
        options={this.state.options}
        placeholder="Choose your country"
        name={this.props.name}
        value={this.value}
        onChange={this.changeHandler}
        components={{
          Option: CountryFlagSelectOption,
          ValueContainer: CountryFlagValueContainer,
        }}
      />
    );
  }
}
