import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "network",
  initialState: {
    network: "",
  },
  reducers: {
    setNetwork: (state, action) => {
      state.network = action.payload;
    },
    getNetwork: (state, action) => {
      return state.network;
    },
  },
});

export default slice.reducer;
export const { setNetwork, getNetwork } = slice.actions;
