import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setAddress } from "../store/address";

const TroonLink = ({ close }) => {
  // const { address } = useSelector((state) => state.address);
  const dispatch = useDispatch();

  function connectWallet() {
    if (window.tronWeb && window.tronWeb.defaultAddress.base58 === false) {
      alert("Please log in to tronlink");
    } else if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
      console.log(">>>>>>>>>>>>>>> " + window.tronWeb.defaultAddress.base58);

      dispatch(setAddress(window.tronWeb.defaultAddress.base58));
      close();

      setTimeout(() => {
        alert(
          "You are logged in. You may now purchase the activated product from the list."
        );
      }, 600);
    } else if (window.tronWeb === undefined) {
      alert(
        "This browser is not available. Tronlink dApp browser or Tronlink extension required."
      );
    }
  }

  return (
    <div className="App-payment">
      <button
        className=" mt-5 btn btn-primary btn-lg btn-block"
        onClick={connectWallet}
      >
        Connect to TronLink
      </button>
    </div>
  );
};

export default TroonLink;
