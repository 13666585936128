import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Nav from "./Nav";
import PuffLoader from "react-spinners/PuffLoader";
import { css } from "@emotion/react";
import packageList from "./const";

const override = css`
  display: block;
  margin: auto;
  margin-top: 50px;
  border-color: red;
`;

const History = () => {
  const [limit, setLimit] = useState(1000);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { address } = useSelector((state) => state.address);
  const { payments } = useSelector((state) => state.payments);

  useEffect(() => {
    getPurchaseHistory();
  }, [limit]);

  async function getPurchaseHistory() {
    setLoading(true);

    let newList;
    if(process.env.REACT_APP_REQUIRED_TRON_LOGIN === 'false'){      
      newList = JSON.parse(JSON.stringify(payments));
    }else{
      newList = await axios
        .post("api/getPurchaseHistory", {
          limit,
          address,
        })
        .then((res) => {
          console.log(res.data);

          return res.data;
          // setList(data);
          // setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            console.log(err.response);
          }
        });
    }

    let data = newList.map((item) => {
      let pkg = packageList.find((item2) => item2.price === item.package_id);
      if (pkg) {
        item.packageName = pkg.desc;
      }
      return item;
    });

    console.log(">>>>>>> list:", data);
    setList(data);
    setLoading(false);
  }

  function Loding() {
    return (
      <div className="loading-small-wrap">
        <PuffLoader color="#fff" loading={true} size={100} />
      </div>
    );
  }

  const listItems = list.map((row, i) => (
    <tr key={i}>
      <td width={"20%"} className="date">
        {row.date}
      </td>
      <td width={"40%"} className="title">
        {row.packageName}
      </td>
      <td width={"30%"} className="amount">
        ${row.amount}
      </td>
    </tr>
  ));

  return (
    <div className="sub-container">
      <div className="content">
        <p className="hisTitle" style={{ marginLeft: 15 }}>
          Purchase History
        </p>
        {/* <p className="text-white description">제목을 누르면 공지사항을 확인할 수 있습니다.</p> */}
        <div className="receive-history history-wrap">
          <table className="history-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Package</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>{list.length > 0 ? listItems : ""}</tbody>
          </table>
          {loading ? (
            <Loding></Loding>
          ) : list.length < limit ? (
            <div className="view-info">
              {list.length === 0 ? "No data" : "End of list"}
            </div>
          ) : (
            <button
              className="btn view-more"
              onClick={() => setLimit(limit + 10)}
            >
              More ...
            </button>
          )}
        </div>
      </div>

      <Nav />
    </div>
  );
};

export default History;
