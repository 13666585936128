import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "name",
  initialState: {
    name: 0,
  },
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    getName: (state, action) => {
      return state.name;
    },
  },
});

export default slice.reducer;
export const { setName, getName } = slice.actions;
