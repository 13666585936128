import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Loading from "./Loading";

const Register = () => {
  const address = useSelector((state) => state.address);
  const [userData, setUserData] = useState({
    id: "",
    referral: "",
    sponsor: "",
  });
  const [isLoding, setLoding] = useState(false);
  const history = useHistory();

  const signup = () => {
    setLoding(true);
    axios
      .post("api/register", {
        address: address,
        name: userData.id,
        referral: userData.referral,
        sponsor: userData.sponsor,
      })
      .then((res) => {
        history.push("/");
        alert("Sign up is complete.");
        setLoding(false);
      })
      .catch((err) => {
        setLoding(false);
        if (err.response) {
          console.log(err.response);
          console.log(err.response.data.message);
          alert(err.response.data.message);
        }
      });
  };

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  if (isLoding) {
    return <Loading />;
  } else {
    return (
      <div>
        <div className="register-wrap">
          <p className="page-title">Register</p>
          <form className="register-form" id="register">
            <div className="form-group">[{address}]</div>
            <div className="form-group">
              <input
                type="text"
                placeholder="ID"
                name="id"
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Referral"
                name="referral"
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Sponsor"
                name="sponsor"
                onChange={onChange}
              />
            </div>
            <button type="button" className="btn btn-register" onClick={signup}>
              Sign up
            </button>
          </form>
        </div>
      </div>
    );
  }
};
export default Register;
