import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "address",
  initialState: {
    address: "",
  },
  reducers: {
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    getAddress: (state, action) => {
      return state.address;
    },
  },
});

export default slice.reducer;
export const { setAddress, getAddress } = slice.actions;
