import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoading } from "../store/loading";
import Iframe from "react-iframe";
import { useSelector } from "react-redux";
import { addPayment } from "../store/payments";
import { useHistory } from "react-router-dom";

import CountrySelector from "./country-selector/CountrySelector";

import Moment from "moment";

import "react-credit-cards/es/styles-compiled.css";

// https://www.npmjs.com/package/react-credit-cards
// https://codesandbox.io/examples/package/react-credit-cards

const Payment = ({ close, selectedPackage }) => {
  const dispatch = useDispatch();
  const { address } = useSelector((state) => state.address);
  const { deposit } = useSelector((state) => state.deposit);
  const { payments } = useSelector((state) => state.payments);

  const [amount, setAmount] = useState(0);

  const [showPaymentIframe, setShowPaymentIframe] = useState(false);
  const paymentReturnUrl = `${process.env.REACT_APP_BASE_URL}/api/processCardPayment`;

  const refPaymentWrap = useRef(null);

  useEffect(() => {
    let d = deposit ? deposit : 0;
    let amount = Number(
      (selectedPackage.price - d) *
        (1 + Number(process.env.REACT_APP_PAYMENT_FEE) / 100)
    ).toFixed(0);
    setAmount(amount);

    if (amount <= 0) {
      alert("This is an unpayable amount");
      close();
    }
  }, [address]);

  // useLayoutEffect((_) => {
  //   const detectMobileKeyboard = (_) => {
  //     // console.log("detectMobileKeyboard", _);
  //     // alert("detectMobileKeyboard");
  //     //refPaymentWrap.current.scrolllIntoView({ block: "end" });
  //   };

  //   window.addEventListener("resize", detectMobileKeyboard);
  // }, []);

  let defaultState = {
    issuer: "",
    focused: "",
    formData: null,
    firstName: "",
    lastName: "",
    country: "",
    city: "",
    addr: "",
    email: "",
  };
  if (process.env.REACT_APP_MODE !== "prod") {
    defaultState = {
      issuer: "",
      focused: "",
      formData: null,
      // number: 4012001037141112,
      // expiry: "0425",
      // cvc: 123,
      firstName: "HONG",
      lastName: "GIL DONG",
      country: "",
      city: "Dubai",
      addr: "123 Test Street",
      email: "wkdb270@gmail.com",
    };
  }
  const [state, setState] = useState(defaultState);
  const scrollTop = () =>
    refPaymentWrap.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });

  const payment = async () => {
    if (amount <= 0) {
      alert("This is an unpayable amount");
      return;
    }

    if (!state.firstName) {
      alert("Please enter your first name.");
      return;
    }

    if (!state.lastName) {
      alert("Please enter your last name.");
      return;
    }

    if (!state.country) {
      alert("Please choose a country.");
      return;
    }

    if (!state.city) {
      alert("Please enter your city.");
      return;
    }

    if (!state.addr) {
      alert("Please enter billing addrsss.");
      return;
    }

    if (!state.email) {
      alert("Please enter your email address.");
      return;
    }

    scrollTop();

    dispatch(setLoading(true));

    if (!window.messageSet) {
      if (window.addEventListener) {
        window.removeEventListener("message", onMessage);
        window.addEventListener("message", onMessage, false);
      } else if (window.attachEvent) {
        window.detachEvent("message", onMessage);
        window.attachEvent("onmessage", onMessage, false);
      }

      function onMessage(event) {
        var data = event.data;
        console.log(">>>>>>>>>>>", data);

        if (data.func === "paymentComplete") {
          dispatch(setLoading(false));
          setShowPaymentIframe(false);

          const formatDate = Moment().format("YYYY-MM-DD");
          dispatch(
            addPayment({
              package_id: selectedPackage.price,
              amount: amount,
              status: "SUCCESS",
              date: formatDate,
            })
          );

          console.log(">>>>", payments);
          setTimeout(() => {
            console.log(">>>>", payments);
          }, 3000);

          close();
          setTimeout(() => {
            alert("Payment has been completed.");
          });
        } else if (data.func === "paymentFail") {
          dispatch(setLoading(false));
          setShowPaymentIframe(false);
          close();

          setTimeout(() => {
            if (data.msg) {
              alert(
                "Payment failed. Please proceed again. (Reason: " +
                  data.msg +
                  ")"
              );
            } else {
              alert("Payment failed. Please proceed again.");
            }
          });
        }
      }
    }

    window.messageSet = true;

    axios
      .post("api/cardPayment", {
        address: address,
        amount: amount,
        package: 1,
        firstName: state.firstName,
        lastName: state.lastName,
        country: state.country,
        city: state.city,
        addr: state.addr,
        email: state.email,
        requiredTronLogin: process.env.REACT_APP_REQUIRED_TRON_LOGIN,
        paymentReturnUrl: paymentReturnUrl,
      })
      .then((res) => {
        if (res.data.status === 1) {
          alert("Payment failed. Please proceed again.\n" + res.data.message);
          dispatch(setLoading(false));
          return;
        }
        console.log(res);
        setShowPaymentIframe(true);

        let data = res.data.data;

        document.getElementById("iframePayment").src = data.paymentCardUrl;

        dispatch(setLoading(false));
      })
      .catch((err) => {
        alert(err.response.data.message);
        dispatch(setLoading(false));
        if (err.response) {
          console.log(err.response);
          console.log(err.response.data.message);
        }
      });
  };

  const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      // setState({ issuer });
    }
  };

  const handleInputFocus = ({ target }) => {};

  const handleCountryChange = (data) => {
    console.log(data);

    state["country"] = data.value;

    setState(JSON.parse(JSON.stringify(state)));
  };

  const handleInputChange = ({ target }) => {
    state[target.name] = target.value;
    setState(JSON.parse(JSON.stringify(state)));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const { issuer } = state;
    const formData = [...e.target.elements]
      .filter((d) => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    setState({ formData });
    this.form.reset();
  };

  return (
    <div
      className={showPaymentIframe ? "App-payment-noscroll" : "App-payment"}
      ref={refPaymentWrap}
    >
      {!showPaymentIframe ? (
        <div>
          <h4>{selectedPackage.desc}</h4>
          <h4>
            <span>
              PRICE : $
              {Math.round(
                selectedPackage.price *
                  (1 + Number(process.env.REACT_APP_PAYMENT_FEE) / 100)
              )}
            </span>
          </h4>
          <hr />
          <div className="payable-amount">Payable Amount: ${amount}</div>
          <hr />

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              Billing Address
              <div className="form-group">
                <div className="row">
                  <div className="col-6">
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      placeholder="First name"
                      required
                      maxLength={20}
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                      value={state.firstName || ""}
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      placeholder="Last name"
                      required
                      maxLength={20}
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                      value={state.lastName || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <CountrySelector
                  name="country"
                  placeholder="Country"
                  value={state.country || ""}
                  onChange={handleCountryChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  placeholder="City"
                  required
                  maxLength={20}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  value={state.city || ""}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="addr"
                  className="form-control"
                  placeholder="Address"
                  required
                  maxLength={200}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  value={state.addr || ""}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  required
                  maxLength={200}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  value={state.email || ""}
                />
              </div>
            </div>
            <input type="hidden" name="issuer" value={state.issuer} />
          </form>

          <div className="row">
            <div className="col-6">
              <button
                className="btn btn-secondary btn-lg btn-block"
                onClick={close}
              >
                Cancel
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-primary btn-lg btn-block"
                onClick={payment}
              >
                Payment
              </button>
            </div>
          </div>
        </div>
      ) : (
        <Iframe
          src=""
          className="payment-iframe"
          name="iframePayment"
          id="iframePayment"
        ></Iframe>
      )}
    </div>
  );
};

export default Payment;
