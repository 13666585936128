let packageList = [
  {
    name: "Bronze",
    price: 100,
    img: "/images/01.png",
    desc: "Bronze Badge + Creta Point 100 + Common Item",
  },
  {
    name: "Silver",
    price: 300,
    img: "/images/02.png",
    desc: "Silver Badge + Creta Point 300 + Uncommon Item",
  },
  {
    name: "Gold",
    price: 1000,
    img: "/images/03.png",
    desc: "Gold Badge + Creta Point 1000 + Magic Item",
  },
  {
    name: "?",
    price: -1,
    img: "/images/09.png",
    desc: "Coming soon!",
  },
  {
    name: "?",
    price: -2,
    img: "/images/09.png",
    desc: "Coming soon!",
  },
  {
    name: "?",
    price: -3,
    img: "/images/09.png",
    desc: "Coming soon!",
  },

  // {
  //   name: "Ruby",
  //   price: 3000,
  //   img: "/images/04.png",
  //   desc: "Ruby Badge + Creta Point 3,000 + Rare Item",
  // },

  // {
  //   name: "Platinum",
  //   price: 6000,
  //   img: "/images/05.png",
  //   desc: "Platinum Badge + Creta Point 6,000 + Legendary Item",
  // },
  // {
  //   name: "Sapphire",
  //   price: 10000,
  //   img: "/images/06.png",
  //   desc: "Sapphire Badge + Creta Point 10,000 + Legendary Item",
  // },
  // {
  //   name: "Emerald",
  //   price: 30000,
  //   img: "/images/07.png",
  //   desc: "Emerald Badge + Creta Point 30,000 + Legendary Item",
  // },
  // {
  //   name: "Diamond",
  //   price: 60000,
  //   img: "/images/08.png",
  //   desc: "Diamond Badge + Creta Point 60,000 + Legendary Item",
  // },
];

export default packageList;
