import React, { useState } from "react";
import { useSelector } from "react-redux";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";

const override = css`
  display: block;
  margin: auto;
  border-color: red;
`;

const Loading = (props) => {
  let [color] = useState("#fff");
  const { loading } = useSelector((state) => state.loading);

  if (!loading) {
    return "";
  } else {
    return (
      <div className="loading-wrap">
        <div className="loading-text">
          <PuffLoader
            color={color}
            loading={loading}
            css={override}
            size={140}
          />
          <div>&nbsp;</div>
          Please wait for a moment.
        </div>
      </div>
    );
  }
};

export default Loading;
