import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "loading",
  initialState: {
    loading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    getLoading: (state, action) => {
      return state.loading;
    },
  },
});

export default slice.reducer;
export const { setLoading, getLoading } = slice.actions;
