import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as MainIcon } from "../images/ic_home.svg";
import { ReactComponent as HistoryIcon } from "../images/ic_bag.svg";
import { ReactComponent as TermsIcon } from "../images/ic_newspaper.svg";
import { ReactComponent as PrivacyPolicyIcon } from "../images/hand-left-outline.svg";

const Nav = () => {
  const location = useLocation();

  const NavDashboard = () => {
    if (location.pathname === "/") {
      return (
        <div className="link active-link">
          <MainIcon className="nav-icon-selected" />
          Home
        </div>
      );
    } else {
      return (
        <Link to="/">
          <div className="link non-link">
            <MainIcon className="nav-icon" />
            Home
          </div>
        </Link>
      );
    }
  };

  const NavHistory = () => {
    if (location.pathname === "/history") {
      return (
        <div className="link active-link">
          <HistoryIcon className="nav-icon-selected" />
          History
        </div>
      );
    } else {
      return (
        <Link to="/history">
          <div className="link non-link">
            <HistoryIcon className="nav-icon" />
            History
          </div>
        </Link>
      );
    }
  };

  const NavTerms = () => {
    if (location.pathname === "/terms") {
      return (
        <div className="link active-link">
          <TermsIcon className="nav-icon-selected" />
          Terms
        </div>
      );
    } else {
      return (
        <Link to="/terms">
          <div className="link non-link">
            <TermsIcon className="nav-icon" />
            Terms
          </div>
        </Link>
      );
    }
  };

  const NavPrivacyPolicy = () => {
    if (location.pathname === "/privacy-policy") {
      return (
        <div className="link active-link">
          <PrivacyPolicyIcon className="nav-icon-selected" />
          Privacy Policy
        </div>
      );
    } else {
      return (
        <Link to="/privacy-policy">
          <div className="link non-link">
            <PrivacyPolicyIcon className="nav-icon" />
            Privacy Policy
          </div>
        </Link>
      );
    }
  };

  return (
    <div id="nav">
      <NavDashboard />
      <NavHistory />
      <NavTerms />
      <NavPrivacyPolicy />
    </div>
  );
};

export default Nav;
