import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CretaIcon from "../images/creta_text_logo.png";
import { setAddress } from "../store/address";

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { address } = useSelector((state) => {
    // history.push("/");
    return state.address;
  });

  useEffect(() => {
    if (!window.troonMessageSet) {
      window.addEventListener("message", function (e) {
        // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support
        if (e.data.message && e.data.message.action === "accountsChanged") {
          console.log("accountsChanged");

          updateAddress(window.tronWeb.defaultAddress.base58);

          history.push("/");
        }

        // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support
        if (e.data.message && e.data.message.action === "connectWeb") {
          console.log("connectWeb event", e.data.message);
          console.log("current address:", e.data.message.data.address);
        }

        if (e.data.message && e.data.message.action === "setNode") {
          let nodeUrl = e.data.message.data.node.fullNode;

          if (process.env.REACT_APP_MODE === "dev") {
            if (nodeUrl === "https://api.nileex.io") {
              console.log("tronLink currently selected the test chain");
            } else {
              alert(
                `Available only on the tron Nile-testnet.\nThe node connected now is '${nodeUrl}'.`
              );
              history.push("/");
            }
          } else {
            if (
              nodeUrl === "https://api.trongrid.io" ||
              nodeUrl === "https://api.tronstack.io"
            ) {
              console.log("tronLink currently selected the main chain");
            } else {
              alert(
                `Available only on the tron mainnet.\nThe node connected now is '${nodeUrl}'.`
              );
              history.push("/");
            }
          }
        }
      });
      window.troonMessageSet = true;
    }
  });

  useEffect(() => {
    connectWallet();
    try {
      updateAddress(window.tronWeb.defaultAddress.base58);
    } catch (e) {}
  }, []);

  async function updateAddress(addr) {
    if (!addr) {
      return;
    }
    console.log(
      `>>>>>>>>>>>>>>>>%%%%%%%%%%%%%%>>> change address: ${addr}`,
      window.tronWeb.defaultAddress
    );
    dispatch(setAddress(addr));
  }

  function connectWallet() {
    if (window.tronWeb && window.tronWeb.defaultAddress.base58 === false) {
      // alert("Please log in to tronlink");
    } else if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
      console.log(
        "tronWeb:",
        window.tronWeb,
        window.tronWeb.defaultAddress.base58
      );

      let nodeUrl = window.tronWeb.fullNode.host;

      if (process.env.REACT_APP_MODE === "dev") {
        if (nodeUrl === "https://api.nileex.io") {
          console.log("tronLink currently selected the test chain");
        } else {
          alert(
            `Available only on the tron Nile-testnet.\nThe node connected now is '${nodeUrl}'.`
          );
          return;
        }
      } else {
        if (
          nodeUrl === "https://api.trongrid.io" ||
          nodeUrl === "https://api.tronstack.io"
        ) {
          console.log("tronLink currently selected the main chain");
        } else {
          alert(
            `Available only on the tron mainnet.\nThe node connected now is '${nodeUrl}'.`
          );
          return;
        }
      }

      // history.push("/loading");
      updateAddress(window.tronWeb.defaultAddress.base58);
      // checkExists(window.tronWeb.defaultAddress.base58);
    } else if (window.tronWeb === undefined) {
      // alert(
      //   "This browser is not available. Tronlink dApp browser or Tronlink extension required."
      // );
    }
  }

  function goHome() {
    history.push("/");
  }

  return (
    <div id="header">
      <span
        className={`title ${process.env.REACT_APP_MODE === "dev" ? "dev" : ""}`}
        onClick={goHome}
      >
        <img src={CretaIcon} className="header-logo" alt="" />
      </span>
    </div>
  );
};

export default Header;
