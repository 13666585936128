import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "package",
  initialState: {
    package: null,
  },
  reducers: {
    setPackage: (state, action) => {
      state.package = action.payload;
    },
    getPackage: (state, action) => {
      return state.package;
    },
  },
});

export default slice.reducer;
export const { setPackage, getPackage } = slice.actions;
