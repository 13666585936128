import { createSlice } from "@reduxjs/toolkit";

const initialPayments = localStorage.getItem('payments')
  ? JSON.parse(localStorage.getItem('payments'))
  : []


const slice = createSlice({
  name: "payments",
  initialState: {
    payments: initialPayments,
  },
  reducers: {
    addPayment: (state, action) => {
      state.payments = [...state.payments, action.payload];
      localStorage.setItem('payments', JSON.stringify(state.payments))
    },
    getPayments: (state, action) => {
      return state.payments;
    },
  },
});

export default slice.reducer;
export const { addPayment, getPayments } = slice.actions;
